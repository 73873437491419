





/**
 * The Version Control protocol
 */
import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Tenant from '@/models/Tenant';
import ArchiveComponent from '@/components/shared/Archive.component.vue';
import {tenantStoreActions, tenantStoreGetter} from '@/stores/tenant.store';

const tenantStore = namespace('tenant');


@Component({
  components: {ArchiveComponent},
})
/**
 * Component to visualize the different Versions of a Tenant
 */
export default class TenantArchiveComponent extends Vue {

  @tenantStore.Action(tenantStoreActions.LOAD_TENANT_ORIGIN)
  private loadTenantOrigin!: (tenantId: string) => Promise<Tenant[]>;
  @tenantStore.Getter(tenantStoreGetter.ACTIVE_TENANT)
  private _tenant!: Tenant;


  private tenantVersions: Tenant[] = [];
  private loaded: boolean = false;


  public async mounted() {
    this.tenantVersions = await this.loadTenantOrigin(this.$route.params.tenantId);
    this.tenantVersions.push(this._tenant);
    this.loaded = true;
  }
}
