






































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {TabItem} from '@/interfaces/TabItem';
import {namespace} from 'vuex-class';
import Tenant from '@/models/Tenant';
import User from '@/models/User';
import TenantMasterDataComponent from '@/components/tenant/TenantMasterData.component.vue';
import SideCardComponent from '@/components/shared/SideCard.component.vue';
import TenantArchiveComponent from '@/components/tenant/TenantArchive.component.vue';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import {tenantStoreGetter} from '@/stores/tenant.store';
import {Permission} from '@/misc/Enums/permission.enum';
import {authStoreGetter} from '@/stores/auth.store';
import PaymentMethodComponent from '@/components/tenant/PaymentMethod.component.vue';
import TenantPaymentListComponent from '@/components/tenant/TenantPaymentList.component.vue';

const tenantStore = namespace('tenant');
const AuthStore = namespace('auth');

@Component({
  components: {
    TenantPaymentListComponent,
    PaymentMethodComponent,
    RJTabs,
    TenantArchiveComponent,
    TenantMasterDataComponent,
    SideCardComponent,
    RoleDashboardComponent: () => import(
        /* webpackChunkName: "tenantManageComponent" */
        '@/components/tenant/RoleDashboard.component.vue'),
    TenantManageComponent: () => import(
        /* webpackChunkName: "tenantManageComponent" */
        '@/components/tenant/TenantManage.component.vue'),
    UserPasswordComponent: () => import(
        /* webpackChunkName: "UserPasswordComponent" */
        '@/components/user/UserPassword.component.vue'),
    UserProfileComponent: () => import(
        /* webPackChunkName: "UserProfileComponent */
        '@/components/shared/UserProfile.component.vue'),
  },
})
export default class SettingsView extends Vue {
  private selectedTab: number = 0;
  public showTenantEditSideCard: boolean = false;


  @tenantStore.Getter(tenantStoreGetter.ACTIVE_TENANT)
  private _tenant?: Tenant;

  @AuthStore.Getter(authStoreGetter.USER)
  private _user!: User;

  get tenant(): Tenant {
    return this._tenant ? this._tenant : new Tenant();
  }

  get user(): User {
    return this._user ? this._user : new User();
  }

  public get tabItems() {
    return [
      {
        key: 'tenant',
        text: this.$t('SETTINGS.TENANT_PROFILE').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.TENANT_UPDATE_OWN) && !!this.tenant.id,
      },
      {
        key: 'paymentMethods',
        text: this.$t('SETTINGS.TENANT_PAYMENT.PAYMENT_METHODS').toString(),
        available: this.$userRoleHandler.isTenantAdmin() && !this.tenant.isTest,
      },
      {
        key: 'charges',
        text: this.$t('SETTINGS.TENANT_PAYMENT.CHARGES').toString(),
        available: (this.$userRoleHandler.isTenantAdmin() || this.$userRoleHandler.isSuperAdmin()) && !this.tenant.isTest,
      },
      {
        key: 'profile',
        text: this.$t('SETTINGS.MY_PROFILE').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.USER_UPDATE_OWN) && !this.$userRoleHandler.isSuperAdmin(),
      },
      {
        key: 'password',
        text: this.$t('GENERAL.PASSWORD').toString(),
        available: !this.$userRoleHandler.isTestUser(),
      }, {
        key: 'role',
        text: this.$t('GENERAL.ROLES').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.ROLE_READ_ALL) && !!this.tenant.id,
      }, {
        key: 'archive',
        text: this.$t('VERSION_CONTROL.ARCHIVE').toString(),
        available: false, // this.$userRoleHandler.hasPermission(Permission.TENANT_UPDATE_OWN) && !!this.tenant.id,
      },
    ];
  }

  private mounted() {
    this.checkQuery();
  }

  @Watch('$route')
  public onRouteChange() {
    this.checkQuery();
  }


   private checkQuery() {
     const tabQuery = new URL(document.URL).searchParams.get('tab');
     if (tabQuery) {
       this.selectedTab = this.tabItems.filter((item) => item.available).findIndex((tab) => tab.key === tabQuery);
     }
   }

  public onEditTenant() {
    this.showTenantEditSideCard = true;
  }

  public onExitModal() {
    this.showTenantEditSideCard = false;
  }
}
