





















































































/**
 * The Version Control protocol
 */
import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import TableComponent from '@/components/shared/table/Table.component.vue';
import * as stripe from 'stripe';
import moment from 'moment';
import PaymentRepository from '@/api/repositories/PaymentRepository';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import Tenant from '@/models/Tenant';
import DBFile from '@/interfaces/DBFile';
import {saveAs} from 'file-saver';

interface TableItem {
  charge: stripe.Stripe.PaymentIntent;
  bill: DBFile;
}

const tenantStore = namespace('tenant');


@Component({
  components: {TableComponent},
})
/**
 * Component to visualize the different Versions of a Tenant
 */
export default class TenantPaymentListComponent extends Vue {

  @Prop({default: new Tenant()})
  public tenant!: Tenant;

  public tableItems: TableItem[] = [];

  public isLoading: boolean = false;
  public showResolveIntentDialog: boolean = false;

  public currentMonthLoading: boolean = false;
  public currentMonthCost: string = '';
  public selectedItem?: TableItem;

  // Repository for everything Payment related
  private paymentRepository: PaymentRepository = RepositoryFactory.get('payment');


  public async mounted() {
    this.isLoading = true;

    // load table items
    try {
      const intents = await this.paymentRepository.getPaymentIntents(this.$route.params.tenantId).then((value) => {
        return value.data.sort((a, b) => a.created - b.created);
      });
      const invoices = await this.paymentRepository.getInvoices(this.$route.params.tenantId).then((value) => {
        return value.records.sort((a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix());
      });
      for (let i = 0; i < intents.length; i++) {
        this.tableItems.push({
          charge: intents[i],
          bill: invoices[i],
        });
      }
    } finally {
      this.isLoading = false;
    }
  }

  public get tableHeaders() {
    return [
      {text: this.$t('SETTINGS.TENANT_PAYMENT.CHARGES_HEADERS.MONTH').toString(), value: 'month', width: '30%'},
      {text: this.$t('SETTINGS.TENANT_PAYMENT.CHARGES_HEADERS.AMOUNT').toString(), value: 'amount', width: '25%'},
      {text: this.$t('SETTINGS.TENANT_PAYMENT.CHARGES_HEADERS.DATE').toString(), value: 'dates', width: '10%'},
      {text: this.$t('SETTINGS.TENANT_PAYMENT.CHARGES_HEADERS.STATUS').toString(), value: 'status', width: '20%'},
      {value: 'download', width: '10%'},
    ];
  }

  public getCurrentMonth() {
    return moment().month() + 1;
  }

  public getChargeMonth(item: stripe.Stripe.PaymentIntent) {
    return this.$t(`GENERAL.MONTH_NUMBER.${moment(item.created * 1000).month()}`).toString() + ' ' + moment(item.created * 1000).year();
  }

  public getChargeAmount(item: stripe.Stripe.PaymentIntent) {
    return (item.amount / 100).toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    });
  }

  public getChargeDate(item: stripe.Stripe.PaymentIntent) {
    return moment(item.created * 1000).toISOString();
  }

  /**
   * Method to look if the Payment intent payid off or not
   * PaymentDelay is created via the PaymentIntent so the times are always synchronized
   * @param item the PaymentIntent
   */
  private getPaymentDelayIndex(item: stripe.Stripe.PaymentIntent) {
    return this.tenant.paymentDelay!.findIndex((delay) => moment(delay).unix() === item.created * 1000);
  }

  /**
   * Returns the Status of the PaymentIntent. Returns a stripe foreign status for externally resolved Intents
   * @param item the PaymentIntent
   */
  public getChargeStatus(item: stripe.Stripe.PaymentIntent): stripe.Stripe.PaymentIntent.Status | 'resolved' {

    if (item.status !== 'canceled') {
      return item.status;
    }
    // Canceled Intends that are resolved outside of swipe get a new status
    if (this.getPaymentDelayIndex(item) <= 0) {
      return 'resolved';
    }
    return item.status;
  }

  public async downloadPDF(item: DBFile) {
    const blob = await this.paymentRepository.downloadInvoice(this.tenant.id, item.id);
    saveAs(blob, item.name);
  }

  public onResolveIntentClick(item: TableItem) {
    this.selectedItem = item;
    this.showResolveIntentDialog = true;
  }

  public async resolveIntent(item: stripe.Stripe.PaymentIntent) {
    const index = this.getPaymentDelayIndex(item);

    // Only resolve intent if it is actually resolvable
    if (index >= 0) {
      try {
        const remainingDelays: Partial<Tenant> = {
          paymentDelay: this.tenant.paymentDelay?.filter((value, i) => i !== index),
        };
        this.tenant = await this.paymentRepository.resolveIntent(remainingDelays);
        this.$notifySuccessSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.RESOLVE_INTENT.SUCCESS');
      } catch {
        this.$notifyErrorSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.RESOLVE_INTENT.ERROR');
      }
    } else {
      this.$notifyInfoSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.RESOLVE_INTENT.NOT_FOUND');
    }
    this.showResolveIntentDialog = false;
  }

  /**
   * function to calculate the cost the current month when no further actions are taken
   */
  public async calculateCurrentMonth() {
    this.currentMonthCost = '';
    this.currentMonthLoading = true;
    this.currentMonthCost = (await this.paymentRepository.getCurrentMonthCosts(this.tenant.id))
        .toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
        });
    this.currentMonthLoading = false;
  }
}
