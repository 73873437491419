var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center  mx-4 my-1"},[_c('div',{staticClass:" d-flex on-background--text"},[(!_vm.currentMonthCost)?_c('v-btn',{staticClass:"big mb-3 ml-8",attrs:{"color":"primary","depressed":"","height":"40","type":"submit","loading":_vm.currentMonthLoading},on:{"click":_vm.calculateCurrentMonth}},[_vm._v(" "+_vm._s(_vm.$t('SETTINGS.TENANT_PAYMENT.CALCULATE_CURRENT_MONTH'))+" ")]):_vm._e(),_c('br'),(_vm.currentMonthCost)?_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('SETTINGS.TENANT_PAYMENT.CURRENT_COSTS_ARE'))+" ")]),_c('br'),_c('span',{staticClass:"justify-center d-flex text-h4"},[_vm._v(_vm._s(_vm.$t(("GENERAL.MONTH_NUMBER." + (_vm.getCurrentMonth()))))+": "+_vm._s(_vm.currentMonthCost))])]):_vm._e()],1)])],1),_c('table-component',{staticClass:"custom-border ",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"height":'calc(80vh - 64px - 105px - 30px)',"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"month",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getChargeMonth(item.charge))+" ")]}},{key:"dates",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDate")(_vm.getChargeDate(item.charge)))+" ")]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getChargeAmount(item.charge))+" ")]}},{key:"status",fn:function(ref){
var item = ref.item;
return [(_vm.$userRoleHandler.isSuperAdmin() && _vm.getChargeStatus(item.charge) !== 'succeeded')?_c('div',[_c('v-btn',{staticClass:"error"},[_c('span',{staticClass:"pa-2",class:_vm.getChargeStatus(item.charge),on:{"click":function($event){return _vm.onResolveIntentClick(item)}}},[_vm._v(" "+_vm._s(_vm.$t(("SETTINGS.TENANT_PAYMENT.CHARGES_STATUS." + (_vm.getChargeStatus(item.charge).toString().toUpperCase()))))+" ")])])],1):_c('div',[_c('span',{staticClass:"status pa-2",class:_vm.getChargeStatus(item.charge)},[_vm._v(" "+_vm._s(_vm.$t(("SETTINGS.TENANT_PAYMENT.CHARGES_STATUS." + (_vm.getChargeStatus(item.charge).toString().toUpperCase()))))+" ")])])]}},{key:"download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadPDF(item.bill)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])}),(_vm.showResolveIntentDialog)?_c('v-dialog',{attrs:{"max-width":"340"},model:{value:(_vm.showResolveIntentDialog),callback:function ($$v) {_vm.showResolveIntentDialog=$$v},expression:"showResolveIntentDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('SETTINGS.TENANT_PAYMENT.RESOLVE_INTENT_DIALOG.HEADER')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('SETTINGS.TENANT_PAYMENT.RESOLVE_INTENT_DIALOG.TEXT')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"button-success","text":""},on:{"click":function($event){return _vm.resolveIntent(_vm.selectedItem)}}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.APPLY'))+" ")]),_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){_vm.showResolveIntentDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.CANCEL'))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }