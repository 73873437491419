






















































































































/**
 * The Version Control protocol
 */
import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {StripeElementCard, StripeElementPayment} from '@vue-stripe/vue-stripe';
import Tenant from '@/models/Tenant';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import PaymentRepository from '@/api/repositories/PaymentRepository';
import {PaymentMethod} from '@/interfaces/PaymentMethod';
import TableComponent from '@/components/shared/table/Table.component.vue';
import ToggleButtonGroupComponent from '@/components/shared/ToggleButtonGroup.component.vue';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import moment from 'moment';
import * as stripe from 'stripe';


const tenantStore = namespace('tenant');


@Component({
  components: {
    ToggleButtonGroupComponent,
    StripeElementCard,
    StripeElementPayment,
    TableComponent,
    RJTextField,
  },
})
export default class PaymentMethodComponent extends Vue {
  @Prop({default: new Tenant()})
  public tenant!: Tenant;

  public stripePublicKey = process.env.VUE_APP_STRIPE_PK;

  public type?: 'sepa_debit' | 'card' = 'card';

  public isLoading: boolean = true;

  public createPMLoading: boolean = false;

  public account?: any;

  public showNewPayment: boolean = false;

  public iban?: string = '';

  public customer?: stripe.Stripe.Customer;

  public paymentMethods: stripe.Stripe.PaymentMethod[] = [];

  public showTogglePaymentDialog: boolean = false;

  public showDeletePaymentMethodDialog: boolean = false;

  public defaultPaymentMethod: string = '';

  public selectedPaymentMethod?: stripe.Stripe.PaymentMethod;

  private paymentRepository: PaymentRepository = RepositoryFactory.get('payment');

  public async mounted() {
    await this.loadPaymentMethods();
  }

  public async loadPaymentMethods() {
    this.isLoading = true;
    try {
      await this.paymentRepository.getPaymentMethods(this.tenant.stripeId!).then((value: any) => {
        this.paymentMethods = value.data;
      });
      await this.paymentRepository.getDefaultPaymentMethod().then((value: any) => {
        this.defaultPaymentMethod = value;
      });
    } finally {
      this.isLoading = false;
    }
  }

  public onToggleDefaultPaymentClick(paymentMethod: stripe.Stripe.PaymentMethod) {
    if (!this.isDefaultPayment(paymentMethod)) {
      this.selectedPaymentMethod = paymentMethod;
      this.showTogglePaymentDialog = true;
    }
  }

  public toggleDefaultPaymentMethod() {
    try {
      this.paymentRepository.changeDefaultPaymentMethod(this.selectedPaymentMethod!.id!);
      this.$notifySuccessSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.CHANGE_PAYMENT_METHOD.SUCCESS');
    } catch (e) {
      this.$notifyErrorSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.CHANGE_PAYMENT_METHOD.ERROR');
    } finally {
      this.showTogglePaymentDialog = false;
      this.loadPaymentMethods();
    }
  }

  public onDeletePaymentMethodClick(item: stripe.Stripe.PaymentMethod) {
    if (!this.isDefaultPayment(item)) {
      this.showDeletePaymentMethodDialog = true;
      this.selectedPaymentMethod = item;
    } else {
      this.$notifyInfoSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.DELETE_PAYMENT_METHOD.IS_DEFAULT');
    }
  }

  public async deletePaymentMethod() {
    try {
      const deletedPM = await this.paymentRepository.deletePaymentMethod(this.selectedPaymentMethod!.id);
      const index = this.paymentMethods.findIndex((method) => method.id === deletedPM.id);
      if (index >= 0) {
        this.paymentMethods.splice(index, 1);
      }
      this.$notifySuccessSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.DELETE_PAYMENT_METHOD.SUCCESS');
    } catch (e) {
      this.$notifyErrorSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.DELETE_PAYMENT_METHOD.ERROR');
    } finally {
      this.showDeletePaymentMethodDialog = false;
    }
  }

  private async submit() {
    this.createPMLoading = true;
    switch (this.type) {
      case 'card': {
        // @ts-ignore
        this.$refs.stripeElement.submit();
        break;
      }
      case 'sepa_debit': {
        this.iban = this.iban!.replaceAll(' ', '');
        const paymentMethod: PaymentMethod = {
          type: 'sepa_debit',
          information: {
            iban: this.iban,
          },
        };
        try {
          const newPM = await this.paymentRepository.createPaymentMethod(paymentMethod);
          this.paymentMethods.push(newPM);
          this.$notifySuccessSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.CREATE_PAYMENT_METHOD.SUCCESS');
        } catch (e) {
          this.$notifyErrorSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.CREATE_PAYMENT_METHOD.ERROR');
        } finally {
          this.showNewPayment = false;
          this.createPMLoading = false;
        }
        break;
      }
    }
    this.iban = '';
  }

  public async tokenCreated(token: any) {
    const paymentMethod: PaymentMethod = {
      type: 'card',
      information: {
        token: token.id,
      },
    };
    try {
      const newPM = await this.paymentRepository.createPaymentMethod(paymentMethod);
      this.paymentMethods.push(newPM);
      this.$notifySuccessSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.CREATE_PAYMENT_METHOD.SUCCESS');
    } catch (e) {
      this.$notifyErrorSimplified('SETTINGS.TENANT_PAYMENT.NOTIFICATION.CREATE_PAYMENT_METHOD.ERROR');
    } finally {
      this.createPMLoading = false;
    }
    this.showNewPayment = false;
  }

  public get paymentTypes() {
    return [
      {text: this.$t('GENERAL.PAYMENT_TYPES.CARD').toString(), value: 'card'},
      {text: this.$t('GENERAL.PAYMENT_TYPES.SEPA_DEBIT').toString(), value: 'sepa_debit'},
    ];
  }

  public get tableHeaders() {
    return [
      {
        text: this.$t('SETTINGS.TENANT_PAYMENT.TABLE_HEADERS.PAYMENT_TYPE').toString(),
        value: 'paymentType',
        width: '20%',
      },
      {text: this.$t('SETTINGS.TENANT_PAYMENT.TABLE_HEADERS.CREATED').toString(), value: 'created', width: '10%'},
      {text: this.$t('SETTINGS.TENANT_PAYMENT.TABLE_HEADERS.EXPIRES_AT').toString(), value: 'expired', width: '10%'},
      {text: this.$t('SETTINGS.TENANT_PAYMENT.TABLE_HEADERS.INFO').toString(), value: 'info', width: '10%'},
      {text: this.$t('SETTINGS.TENANT_PAYMENT.TABLE_HEADERS.DATA').toString(), value: 'data', width: '20%'},
      {text: '', value: 'default', width: '10%'},
      {text: '', value: 'actions', width: '5%', class: 'justify-start'},
    ];
  }

  public getPaymentData(item: stripe.Stripe.PaymentMethod) {
    switch (item.type) {
      case 'card': {
        return '**** **** **** ' + item.card!.last4;
      }
      case 'sepa_debit': {
        return '**** **** **** **** ** ' + item.sepa_debit!.last4;
      }
    }
  }

  public getPaymentInfo(item: stripe.Stripe.PaymentMethod) {
    switch (item.type) {
      case 'card': {
        return item.card!.brand;
      }
      case 'sepa_debit': {
        return '';
      }
    }
  }

  public getPaymentExpired(item: stripe.Stripe.PaymentMethod): string {
    switch (item.type) {
      case 'card': {
        return this.$t(`GENERAL.MONTH_NUMBER.${item.card!.exp_month}`).toString() + ' ' + item.card!.exp_year;
      }
      default: {
        return '';
      }
    }
  }

  public getCreatedAt(item: stripe.Stripe.PaymentMethod) {
    return moment.unix(item.created).toISOString();
  }

  public isDefaultPayment(item: stripe.Stripe.PaymentMethod) {
    return item.id === this.defaultPaymentMethod;
  }

  public get locale() {
    return this.$i18n.locale;
  }
}
