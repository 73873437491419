












































































/**
 * Represents the master data of a tenant
 */
import { Component, Prop, Vue } from 'vue-property-decorator';
import Tenant from '@/models/Tenant';
import SideCardComponent from '@/components/shared/SideCard.component.vue';
import TenantManageComponent from '@/components/tenant/TenantManage.component.vue';
import BaseDataCellComponent from '@/components/shared/BaseDataCell.component.vue';

@Component({
  components: {BaseDataCellComponent, SideCardComponent, TenantManageComponent},
})
export default class TenantMasterDataComponent extends Vue {

    @Prop({default: undefined})
    public readonly tenant: Tenant | undefined;

    public onEditTenant() {
      this.$emit('edit');
    }
}
